












import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Criteria from '@/models/price/manager/Criteria'

@Component({})
export default class EditSidebar extends Vue {
  @Prop({ required: true })
  private criteria: Array<Criteria>

  @Prop()
  private loading: boolean

  private toggle(c: Criteria) {
    if (this.loading) {
      return
    }
    c.selected = !c.selected
  }
}
